import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ModalInput.css'; // Add your CSS styles here
import ExitIcon from '../assets/icons/CloseIcon.png';

const ModalSurvey = ({ url, onFinished }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Notify Unity to disable input
    const disableUnityInput = new Event('onModalInputActive');
    window.dispatchEvent(disableUnityInput);

    return () => {
      // Notify Unity to enable input
      const enableUnityInput = new Event('onModalInputClosed');
      window.dispatchEvent(enableUnityInput);
    };
  }, [onFinished]);

  const onClose = () => {
    onFinished();
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return ReactDOM.createPortal(
    <div className="modal fullscreen">
      {/* Loading indicator */}
      {isLoading && <div className="loading-indicator">Loading...</div>}

      {/* Fullscreen iframe */}
      <iframe
        src={url}
        title="SurveyMonkey Survey"
        width="100%"
        height="100%"
        allowFullScreen
        style={{ border: 'none', display: isLoading ? 'none' : 'block' }}
        onLoad={handleIframeLoad}
      />

      {/* Exit button overlay */}
      <div className="exit-icon-overlay">
        <div className="exit-icon-container" onClick={onClose}>
          <img src={ExitIcon} alt="Exit" />
        </div>
      </div>
    </div>,
    document.body
  );
};

// Shouldnt be global vars like this, but its fine for now
let openSurveyCallback = null;
let closeSurveyCallback = null;

export const openSurveyModal = (url, onFinished) => {
  if (openSurveyCallback) {
    openSurveyCallback(url, onFinished);
  }
};

export const closeSurveyModal = () => {
  if (closeSurveyCallback) {
    closeSurveyCallback();
  }
};

export const ModalSurveyWrapper = () => {
  const [modalProps, setModalProps] = useState(null);

  openSurveyCallback = (url, onFinished) => {
    setModalProps({ url, onFinished });
  };

  closeSurveyCallback = () => {
    setModalProps(null);
  };

  return (
    <>
      {modalProps && (
        <ModalSurvey
          {...modalProps}
          onFinished={(value) => {
            modalProps.onFinished(value);
            setModalProps(null);
          }}
        />
      )}
    </>
  );
};
