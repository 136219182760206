import config from '../config';

export const generateGuestUID = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'guest-' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export const checkGuestExpiration = () => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime >= parseInt(expirationTime)) {
            localStorage.removeItem('expirationTime');
            localStorage.removeItem('guestToken');
            localStorage.removeItem('guestID');
            localStorage.removeItem('isGuest');
            console.log('Data expired. Cleared saved data.');
            return true; // Data expired
        } else {
            console.log('Data still valid until:', new Date(parseInt(expirationTime)).toLocaleString());
            return false; // Data still valid
        }
    } else {
        console.log('No expiration data found.');
        return true; // No expiration data found
    }
};

export const  isGuest = () => {
    checkGuestExpiration();
    return localStorage.getItem('isGuest') === 'true';
};

export const GenerateGuestToken = async (user_id) => {
    try {
        if (checkGuestExpiration()) {
            console.log(`[GenerateGuestToken] Generating new guest token for id: ${user_id}`);
            const bodyText = `grant_type=client_credentials&device_id=${user_id}`;
            const credential = `${config.keycloakGuestId}:${config.keycloakGuestSecret}`;
            const utf8 = new TextEncoder().encode(credential);
            const credential_base64 = btoa(String.fromCharCode(...utf8));
            const response = await fetch(`${config.keycloakAuthURL}/realms/${config.keycloakRealm}/protocol/openid-connect/token`, {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${credential_base64}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: bodyText
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(`guest info: ${JSON.stringify(data)}`);
            const seconds  = data.expires_in; // server returns: 18000, which is about 5 hours
            localStorage.setItem('expirationTime', new Date().getTime() + (seconds * 1000)); 
            localStorage.setItem('guestToken', data.access_token);
        }
        console.log(`[GenerateGuestToken] Guest token: ${localStorage.getItem('guestToken')}`);
        console.log(`[GenerateGuestToken] Guest token expires at: ${new Date(parseInt(localStorage.getItem('expirationTime'))).toLocaleString()}`);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const SignInAsGuest = async () => {
    try {
        if (checkGuestExpiration()) {
            localStorage.setItem('guestID', generateGuestUID());
            await GenerateGuestToken(localStorage.getItem('guestID'));
            localStorage.setItem('isGuest', 'true');
        }
        console.log(`[SignInAsGuest] GuestID is: ${localStorage.getItem('guestID')}`);
        window.location.reload();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const checkForceNewGuest = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    
    // Check force signin as guest
    const forceGuest = urlParams.get('forceGuest');
    if(forceGuest == "true")
    {
        await SignInAsGuest();
    }

    // for mini-load testing
    const loadTest = urlParams.get('loadtest');
    const isAdmin = localStorage.getItem('isAdmin'); 
    if(loadTest && isAdmin === "true")
    {
        console.log("[checkForceNewGuest] loadTest enabled, will sign in as new guest")
        localStorage.setItem('guestID', generateGuestUID());
        var bodyText = `grant_type=client_credentials&device_id=${localStorage.getItem('guestID')}`;
        const credential = `${config.keycloakGuestId}:${config.keycloakGuestSecret}`;
        const utf8 = new TextEncoder().encode(credential);
        const credential_base64 =  btoa(String.fromCharCode(...utf8));
        const response = await fetch(`${config.keycloakAuthURL}/realms/${config.keycloakRealm}/protocol/openid-connect/token`, {
            method: 'POST',
            headers: {
                Authorization: `Basic ${credential_base64}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: bodyText
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(`guest info: ${JSON.stringify(data)}`);
        const seconds  = data.expires_in; // server returns: 18000, which is about 5 hours
        localStorage.setItem('expirationTime', new Date().getTime() + (seconds * 1000)); 
        localStorage.setItem('guestToken', data.access_token);
        localStorage.setItem('isGuest', 'true');

        // For testing
        const rltValue = localStorage.getItem('rlt');
        if (rltValue === "true"){
            console.log('');
            localStorage.setItem('UseAutoPlay', 'true');
            localStorage.setItem('RunningInDebug', 'true');
            const randomNumber = Math.floor(Math.random() * 1001);
            localStorage.setItem('NicknameOverride', `player_${randomNumber}`);
        }
        console.log("[checkForceNewGuest] created new guest player")
    }
};
